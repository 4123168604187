class LoggerService {
  /**
   * Write a 'log' level log.
   */
  private log(
    type: 'info' | 'error' | 'warn' | 'debug' | 'verbose',
    ...params: any[]
  ) {
    // if (!process.env.DEBUG) {
    //   return;
    // }

    if (type === 'info') {
      return console.info(...params);
    }
    if (type === 'error') {
      return console.error(...params);
    }
    if (type === 'warn') {
      return console.warn(...params);
    }
    if (type === 'debug') {
      return console.debug(...params);
    }

    return console.log(`[${type}]`, ...params);
  }

  /**
   * Write a 'log' level log.
   */
  info(message: any, ...optionalParams: any[]) {
    this.log('info', message, ...optionalParams);
  }
  /**
   * Write an 'error' level log.
   */
  error(message: any, ...optionalParams: any[]) {
    this.log('error', message, ...optionalParams);
  }

  /**
   * Write a 'warn' level log.
   */
  warn(message: any, ...optionalParams: any[]) {
    this.log('warn', message, ...optionalParams);
  }

  /**
   * Write a 'debug' level log.
   */
  debug?(message: any, ...optionalParams: any[]) {
    this.log('debug', message, ...optionalParams);
  }

  /**
   * Write a 'verbose' level log.
   */
  verbose?(message: any, ...optionalParams: any[]) {
    this.log('verbose', message, ...optionalParams);
  }
}

const logger = new LoggerService();

export default logger;
