import { useCallback, useEffect } from 'react';
import {
  getTokens,
  TokenProps,
  setTokens as setLocalTokens,
  removeTokens as removeLocalTokens,
  getCurrentProfile,
  setCurrentProfile as setLocalCurrentProfile,
  getAccessTokenData,
  isTokenDataExpired,
  AccessTokenData,
} from '../utils/user';
import useReactiveVar, { makeVar } from './useReactiveVar';
import logger from '../utils/logger';

type TokensVar = {
  tokens: TokenProps | null;
  accessTokenData: AccessTokenData | null;
  profile: string;
};

function getInitTokenVar(): TokensVar {
  const localTokens = getTokens();
  const accessTokenData = localTokens?.accessToken
    ? getAccessTokenData(localTokens.accessToken)
    : null;
  const isExpired = accessTokenData
    ? isTokenDataExpired(accessTokenData)
    : true;
  return {
    tokens: isExpired ? null : localTokens,
    accessTokenData: isExpired ? null : accessTokenData,
    profile: getCurrentProfile(),
  };
}

export const tokensVar = makeVar<TokensVar>(getInitTokenVar());

/**
 * Hook to handle tokens set/remove
 */
export default function useTokens() {
  const data = useReactiveVar(tokensVar);

  // state tokens -> local tokens
  // useEffect(() => {
  //   if (data?.tokens) {
  //     setLocalTokens(data.tokens);
  //   } else {
  //     removeLocalTokens();
  //   }
  // }, [data?.tokens]);

  const setHookTokens = useCallback((tokens: TokenProps) => {
    setLocalTokens(tokens);
    tokensVar(getInitTokenVar());
  }, []);

  const removeHookTokens = useCallback(() => {
    removeLocalTokens();
    tokensVar(getInitTokenVar());
  }, []);

  const setHookCurrentProfile = useCallback((profile: string) => {
    setLocalCurrentProfile(profile);
    tokensVar(getInitTokenVar());
  }, []);

  /**
   * Add and switch to tokens profile
   */
  const switchTokens = useCallback(
    (tokens: TokenProps) => {
      try {
        const data = getAccessTokenData(tokens.accessToken);
        setLocalCurrentProfile(data.profile);
        setLocalTokens(tokens);
        tokensVar(getInitTokenVar());
      } catch (error) {
        logger.warn(
          `switchTokens cannot decode token ${tokens.accessToken}`,
          error,
        );
      }
    },
    [setHookTokens],
  );

  return {
    data,
    setTokens: setHookTokens,
    removeTokens: removeHookTokens,
    setCurrentProfile: setHookCurrentProfile,
    switchTokens,
    isUserLogged: !!data?.tokens?.userId,
    isGuestProfile: data.accessTokenData?.profile === 'guest',
  };
}
