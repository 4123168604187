// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library


// https://github.com/auth0/jwt-decode
function decode(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

// this help dev correct env
const tokenPrefix = 'playward';

/**
 * To support switch token key
 */
const profileKey = `${tokenPrefix}::profile`;
export function setCurrentProfile(profile: string) {
  localStorage.setItem(profileKey, `${tokenPrefix}::${profile}`);
}

/**
 * Return current profile, or default profile (guest)
 * if not found, get token key guest type by default
 */
export function getCurrentProfile() {
  if (typeof window === 'undefined') {
    // on SSR
    return 'guest';
  }
  const tokenKey = localStorage.getItem(profileKey);
  if (tokenKey) {
    return tokenKey.replace(`${tokenPrefix}::`, '');
  }
  setCurrentProfile('guest');
  return getCurrentProfile();
}

/**
 * Base on profile, return current key to store auth
 */
function getTokenKey() {
  return `${tokenPrefix}::${getCurrentProfile()}`;
}

export type TokenProps = {
  accessToken: string;
  userId: string;
};

type HasuraClaimProps = TokenProps & {
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': [string];
    'x-hasura-default-role': string;
    'x-hasura-user-id': string;
    'x-hasura-org-id': string;
    'x-hasura-groups': string;
  };
};

export type AccessTokenData = {
  userId: string;
  role: string,
  roles: string[],
  profile: string,
  exp: number,
}

export interface TokenPayloadProps {
  exp: number;
  iat: number;
}

/* istanbul ignore next */
export const getTokens = (): TokenProps | null => {
  try {
    const localTokens = localStorage.getItem(getTokenKey()) as string;
    return JSON.parse(localTokens);
  } catch (err) {
    return null;
  }
};

/* istanbul ignore next */
export const setTokens = (nextTokens?: TokenProps): void => {
  const currentTokens = getTokens();
  const tokens = nextTokens || currentTokens;
  if (!tokens) {
    return;
  }

  if (currentTokens?.accessToken !== tokens.accessToken) {
    const stringifiedData = JSON.stringify(tokens);
    localStorage.setItem(getTokenKey(), stringifiedData);
  }
};

/* istanbul ignore next */
export const removeTokens = (): void => {
  localStorage.removeItem(getTokenKey());
  sessionStorage.removeItem(getTokenKey());
};

/* istanbul ignore next */
export const isAuthenticated = (tokens = getTokens()): boolean => {
  if (tokens) {
    const { accessToken } = tokens;
    const decoded = decode(accessToken);

    if (decoded) {
      const { exp } = decoded as TokenPayloadProps;
      const timeNow = Math.floor(Date.now() / 1000);

      return timeNow < exp;
    }
  }

  return false;
};

/**
 * Helper to decode token
 */
export const getAccessTokenData = (accessToken: string): AccessTokenData => {
  const decoded = decode(accessToken);
  const userData = decoded?.['https://hasura.io/jwt/claims'];
  const session = userData?.['x-hasura-session'];
  const userId = userData?.['x-hasura-user-id'];
  if (!session || !userId) {
    throw new Error('Cannot getDecodedToken');
  }
  return {
    userId,
    role: userData['x-hasura-default-role'],
    roles: userData['x-hasura-allowed-roles'],
    profile: session === 'temp' ? 'guest' : userId,
    exp: decoded.exp as number,
  };
};

/**
 * Return true if token expired inNext seconds
 * false for expired or invalid token
 */
export const isTokenDataExpired = (decoded: { exp: number }, inNext = 0) => {
  try {
    const exp = decoded.exp;
    const timeNow = Math.floor(Date.now() / 1000);
    if (!exp || timeNow + inNext < exp) {
      throw 'Expired!';
    }
    return true;
  } catch {
    return false;
  }
};

/**
 * Get header authorization tokens
 * use  non expired tokens only
 */
export async function getAuthorizationHeaders(): Promise<{
  authorization?: string;
}> {
  const tokens = getTokens();
  if (tokens && !isTokenDataExpired(getAccessTokenData(tokens.accessToken))) {
    return {
      authorization: `Bearer ${tokens.accessToken}`,
    };
  }
  return {};
}
