// https://chakra-ui.com/guides/integrations/with-gatsby
import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  fonts: {
    body: 'SVN-Gotham',
    gotham: 'SVN-Gotham', // weight: 500, 100
    gothamUltra: 'SVN-Gotham Ultra',
    gothamBook: 'SVN-Gotham Book',
  },
  colors: {
    biscay: '#1b4762',
    gold: '#F4CC75',
    jaffa: '#F38832',
    cean: '#238abb',
  },
  components: {
    Button: {
      defaultProps: {
        size: 'sm',
        variant: 'solid',
      },
      baseStyle: () => {
        return {
          fontFamily: 'gotham',
          fontWeight: 500,
        };
      },
      variants: {
        solid: {
          color: 'white',
          fontFamily: 'gotham',
          fontWeight: 500,
          textTransform: 'uppercase',
          bgGradient: 'linear-gradient(45deg, #C07AF4 0%, #66C7FE 100%)',
          _hover: {
            bgGradient: 'linear-gradient(45deg, #C07AF4 0%, #66C7FE 100%)',
          },
          _active: {
            bgGradient: 'linear-gradient(45deg, #C07AF4 0%, #66C7FE 100%)',
            opacity: 0.75,
          },
        },
        eoeo: {
          color: 'white',
          fontFamily: 'gotham',
          fontWeight: 500,
          textTransform: 'uppercase',
          bgGradient: 'linear-gradient(45deg, #F4973C 0%, #BF58E1 100%)',
          _hover: {
            bgGradient: 'linear-gradient(45deg, #F4973C 0%, #BF58E1 100%)',
            _disabled: {
              bgGradient: 'linear-gradient(45deg, #F4973C 0%, #BF58E1 100%)',
            },
          },
          _active: {
            bgGradient: 'linear-gradient(45deg, #F4973C 0%, #BF58E1 100%)',
            opacity: 0.75,
          },
        },
      },
    },
  },
});
