const config = {
  mainMenus: [
    { title: 'Dashboard', path: '/dashboard' },
    { title: 'Add Game', path: '/games' },
    { title: 'My Account', path: '/account' },
    { title: 'Logout', path: '#logout' },
    { title: 'Language', path: '#language' },
  ],
  api: {
    url: 'https://dp-be.vn09.com',
    // url: 'http://localhost:3000',
  },
  graphql: {
    api: 'https://dp-gql.vn09.com/v1',
    url: 'https://dp-gql.vn09.com/v1/graphql',
    ws: 'wss://dp-gql.vn09.com/v1/graphql',
  },
  media: {
    // uploadUrl: 'https://dp-be.vn09.com/file/upload',
    // baseUrl: 'https://dp-be.vn09.com/file/image',
    baseUrl: 'https://media.depire.com',
  },
};

export default config;
